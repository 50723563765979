import { BrowserRouter, Routes, Route } from "react-router-dom";

import { PlansAndContractsPage } from "../page/PlansAndContractsPage";
import { RoutesGuard } from "./routesGuard";
import { ContractsAndAmendmentsPage } from "src/page/ContractsAndAmendmentsPage";
import { ContractSummaryPage } from "src/page/ContractSummaryPage";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RoutesGuard />}>
          <Route
            path="/contract-manager/plan"
            element={<PlansAndContractsPage />}
          />
          <Route
            path="/contract-manager/contracts-and-amendments/:contractId"
            element={<ContractSummaryPage />}
          />
          <Route
            path="/contract-manager/contracts-and-amendments"
            element={<ContractsAndAmendmentsPage />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
