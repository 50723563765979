import { useContractModules } from "@flash-tecnologia/hros-web-utility";

import { PageHeader } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Breadcrumb } from "../../components/Breadcrumb";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "../../components/Breadcrumb/styled";

import { PeoplePlanPage } from "../../components/pages/PeoplePlanPage";
import { SecondaryTabs } from "../../components/SecondaryTabs";
import { StyledTitle } from "./styled";

const PlansAndContractsPage = () => {
  type IPages = {
    key: number;
    title: string;
    component: any;
  };

  const peopleContractModules = useContractModules();

  const planPages = [
    {
      key: 1,
      title: "Gestão de Pessoas",
      component: (
        <PeoplePlanPage peopleContractModules={peopleContractModules} />
      ),
    },
  ];

  const [selectedSecondaryTab, setSelectedSecondaryTab] = useState<IPages>(
    planPages[0]
  );

  const { state } = useLocation();
  const { tab: redirectedTab } = (state as any) || {};
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedSecondaryTab(planPages[redirectedTab || 0]);
  }, []);

  return (
    <>
      <PageHeader>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={[
              {
                onClick: () => {
                  navigate(`/settings`, { state: { tab: 2 } });
                },
                label: "Gerenciar empresa",
                active: true,
              },
              {
                onClick: () => ({}),
                label: "Planos",
              },
            ]}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <Grid container spacing={3} style={{ padding: "0px 32px 32px 32px" }}>
        <Grid item md={12} xs={12}>
          <StyledTitle variant="headline6">Planos</StyledTitle>
        </Grid>
        <Grid item md={2} xs={12}>
          <>
            <SecondaryTabs
              secondaryTabs={planPages}
              selectedSecondaryTab={selectedSecondaryTab}
              setSelectedSecondaryTab={setSelectedSecondaryTab}
            />
          </>
        </Grid>
        <Grid item md={10} xs={12}>
          {selectedSecondaryTab?.component}
        </Grid>
      </Grid>
    </>
  );
};

export { PlansAndContractsPage };
