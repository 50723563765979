import { StyledBox, StyledTag, StyledText, StyledTitle } from "./styled";

const PlanBox = ({ values, editPlan = false }) => {
  const { totalValue, startDate, active } = values;

  return (
    <StyledBox>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
          <StyledTitle variant="body3">Seu Plano</StyledTitle>
          {active == "ACTIVE" ? (
            <StyledTag
              style={{ backgroundColor: "#D7F9F3", color: "#094338" }}
              variant="primary"
            >
              Ativo
            </StyledTag>
          ) : active == "trial" || active == "trial_over" ? (
            <StyledTag variant="info">Em teste</StyledTag>
          ) : (
            <StyledTag variant="disabled">Inativo</StyledTag>
          )}
        </div>
        {editPlan && (
          <a href="/contract-manager/cancel-plan">
            <StyledText
              style={{ fontWeight: 700, cursor: "pointer" }}
              variant="body4"
            >
              Cancelar assinatura
            </StyledText>
          </a>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          gap: "40px",
        }}
      >
        {totalValue && (
          <div>
            <StyledTitle variant="headline6">{totalValue}</StyledTitle>
            <StyledText variant="body4">Valor por pessoa</StyledText>
          </div>
        )}
        {startDate && (
          <div>
            <StyledTitle variant="body3">{startDate}</StyledTitle>
            <StyledText variant="body4">Início do plano</StyledText>
          </div>
        )}
      </div>
    </StyledBox>
  );
};

export { PlanBox };
