import { Grid, Skeleton } from "@mui/material";
import { ConsultantContactBox } from "@components/ConsultantContactBox";
import { trpc } from "@api/client";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { PageContainer, PageHeader } from "@flash-tecnologia/hros-web-ui-v2";
import { Breadcrumb } from "@components/Breadcrumb";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
  StyledTitle,
} from "@components/Breadcrumb/styled";
import {
  StyledBox,
  StyledGapHorizontalContainer,
  StyledPinkCircleWrapper,
  StyledIcon,
  StyledHorizontalContainer,
  StyledVerticalSpacing,
  StyledText,
  StyledGapVerticalContainer,
} from "./styled";
import { ContractPdfModal } from "@components/Modals/ContractPdfModal";
import { ContractSummaryBox } from "@components/ContractSummaryBox";
import { useGetContractSignedUrl } from "../ContractsAndAmendmentsPage/hooks";

const ContractSummaryPage = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const { contractId = "" } = useParams();
  const {
    data: contractSummary,
    isLoading,
    refetch,
  } = trpc.getContractSummary.useQuery({
    contractId,
  });
  const { getContractSignedUrl } = useGetContractSignedUrl();

  const openPdfModal = async () => {
    setIsPdfLoading(true);
    setModalOpen(true);

    const pdfUrl = await getContractSignedUrl({ contractId });
    setPdfUrl(pdfUrl.url);

    setIsPdfLoading(false);
  };

  if (!isLoading && !contractSummary) refetch();

  if (isLoading)
    return (
      <PageContainer>
        <StyledPageHeaderColumn>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Skeleton variant="rectangular" width="100%" height="185px" />
            </Grid>
            <Grid item md={12} lg={6}>
              <Skeleton variant="rectangular" width="100%" height="300px" />
            </Grid>
          </Grid>
        </StyledPageHeaderColumn>
      </PageContainer>
    );

  if (!contractSummary) return null;

  return (
    <PageContainer>
      <PageHeader>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={[
              {
                onClick: () => {
                  navigate(`/settings`, { state: { tab: 2 } });
                },
                label: "Gerenciar empresa",
                active: true,
              },
              {
                onClick: () =>
                  navigate(`/contract-manager/contracts-and-amendments`),
                label: "Contratos e aditivos",
                active: true,
              },
              {
                // TODO: add correct label
                label: contractSummary.displayName,
              },
            ]}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <Grid container spacing={3} style={{ padding: "0px 32px 32px 32px" }}>
        <Grid item xs={12}>
          <ContractSummaryBox
            status={contractSummary.status}
            displayName={contractSummary.displayName}
            signDate={contractSummary.signDate}
            onContractClick={openPdfModal}
          />
        </Grid>
        <StyledVerticalSpacing size="xs" />
        {contractSummary?.commercialConditions?.length && (
          <Grid item md={12} lg={6}>
            <StyledBox>
              <StyledGapHorizontalContainer
                style={{ alignItems: "flex-start" }}
              >
                <StyledPinkCircleWrapper size={50}>
                  <StyledIcon name="IconReceipt2" size={20} />
                </StyledPinkCircleWrapper>
                <div>
                  <StyledHorizontalContainer>
                    <StyledTitle variant="body2" style={{ fontWeight: 700 }}>
                      Resumo das condições comerciais
                    </StyledTitle>
                  </StyledHorizontalContainer>
                  <StyledVerticalSpacing size="xs" />
                  <StyledGapVerticalContainer>
                    {contractSummary?.commercialConditions?.map(
                      (ccv, index) => (
                        <div key={ccv.name + index}>
                          <StyledText
                            variant="body3"
                            style={{ fontWeight: 700 }}
                          >
                            {ccv.name}
                          </StyledText>
                          <StyledText variant="body3">{ccv.value}</StyledText>
                        </div>
                      )
                    )}
                  </StyledGapVerticalContainer>
                </div>
              </StyledGapHorizontalContainer>
            </StyledBox>
          </Grid>
        )}

        <Grid item md={12} xs={12}>
          <ConsultantContactBox cancel={false} />
        </Grid>
      </Grid>
      <ContractPdfModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        isLoading={isPdfLoading}
        pdfUrl={pdfUrl}
      />
    </PageContainer>
  );
};

export { ContractSummaryPage };
