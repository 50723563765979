import { useEffect, useState } from "react";

import { Grid, Skeleton } from "@mui/material";

import { ConsultantContactBox } from "../../../../components/ConsultantContactBox";

import { PlanBox } from "@components/PlanBox";
import { IncludedServiceCard } from "@components/IncludedServicesCard";
import { StyledTitle, StyledText } from "./styled";

import { trpc } from "../../../../api/client";

import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

const PeoplePlan = ({
  peopleContractModules,
}: {
  peopleContractModules: {
    contractId: string;
    modules: string[];
  };
}) => {
  const [value, setValue] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [contractId, setContractId] = useState("");
  const [contractStatus, setContractStatus] = useState("");

  const { selectedCompany } = useSelectedCompany();

  const { data, isLoading } = trpc.getContractsByCompany.useQuery({
    companyId: selectedCompany.id,
  });

  const planValues = {
    totalValue: value,
    startDate: initialDate,
    active: contractStatus,
  };

  const statusHierarchy = ["ACTIVE", "WAITING_SIGNATURE", "CANCELLED"];

  const modules: {
    icon: IconTypes;
    service: string;
    description: string;
    id: string;
  }[] = [
    {
      icon: "IconHierarchy",
      service: "Organograma",
      description: "Visualize e organize a estrutura hierárquica.",
      id: "orgchart",
    },
    {
      icon: "IconChartPie",
      service: "Analytics",
      description: "Veja dashboards com análises personalizadas.",
      id: "analytics",
    },
    {
      icon: "IconSpeakerphone",
      service: "Engajamento",
      description: "Crie e compartilhe comunicações e pesquisas.",
      id: "engagement",
    },
    {
      icon: "IconSchool",
      service: "Treinamentos",
      description: "Ofereça treinamentos para desenvolver habilidades.",
      id: "lms",
    },
    {
      icon: "IconLayoutKanban",
      service: "Fluxo de pessoas",
      description: "Controle os fluxos de admissão e desligamento.",
      id: "flows",
    },
    {
      icon: "IconRocket",
      service: "Performance",
      description: "Avalie e melhore o desempenho das pessoas.",
      id: "performance",
    },
    {
      icon: "IconClock",
      service: "Controle de jornada",
      description: "Gerencie e acompanhe o tempo de trabalho.",
      id: "time-and-attendance",
    },
  ];

  useEffect(() => {
    (async () => {
      if (data) {
        const peopleContracts = data?.filter(
          (contract) =>
            contract?.planIds?.includes("people") ||
            contract?.planIds?.includes("sales_flash_rh_ops") ||
            contract?.planIds?.includes("people_sales_default") ||
            contract?.planIds?.includes("sales_flash_rh_full") ||
            contract?.planIds?.includes("sales_flash_rh_strategic") ||
            contract?.planIds?.includes("people_fc_sales_default") ||
            contract?.planIds?.includes("stand_alone_people_manage_flows") ||
            contract?.planIds?.includes(
              "stand_alone_people_time_and_attendance"
            ) ||
            contract?.planIds?.includes("stand_alone_people_learning_module") ||
            contract?.planIds?.includes(
              "stand_alone_people_performance_module"
            ) ||
            contract?.planIds?.includes(
              "stand_alone_people_engagement_module"
            ) ||
            contract?.planIds?.includes(
              "stand_alone_people_people_analytics_module"
            ) ||
            contract?.planIds?.includes(
              "stand_alone_people_analytics_module"
            ) ||
            contract?.planIds?.includes("stand_alone_people_orgchart") ||
            contract?.planIds?.includes("stand_alone_people_folha_certa")
        );

        const orderedPeopleContract = peopleContracts?.sort((a, b) => {
          return (
            statusHierarchy.indexOf(a?.status) -
            statusHierarchy.indexOf(b?.status)
          );
        });

        const peopleContract = orderedPeopleContract?.[0];

        if (peopleContract) {
          setContractId(peopleContract?.id);
          setContractStatus(peopleContract?.status);

          if (peopleContract?.signDate != null) {
            setInitialDate(peopleContract?.signDate);
          }

          const price: any = peopleContract?.commercialConditions?.find(
            (condition) => condition?.id == "people_price_per_user"
          );

          const priceWithDiscount: any =
            peopleContract?.commercialConditions?.find(
              (condition) =>
                condition?.id == "people_price_per_user_with_discount"
            );

          if (priceWithDiscount) {
            setValue(
              `R$ ${(priceWithDiscount?.value / 100)
                ?.toFixed(2)
                ?.replace(".", ",")}`
            );
          } else if (price) {
            setValue(
              `R$ ${(price?.value / 100)?.toFixed(2)?.replace(".", ",")}`
            );
          } else {
            setValue("");
          }
        }
      }
    })();
  }, [data]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height="185px" />
          ) : (
            <PlanBox values={planValues} />
          )}
        </Grid>

        <Grid item md={12}>
          <StyledTitle variant="headline7">
            Confira tudo que está incluso no seu plano
          </StyledTitle>
          <StyledText variant="body3">
            Aproveite cada ferramenta disponível para melhorar a gestão do seu
            time.
          </StyledText>
        </Grid>
        {peopleContractModules?.modules?.map((service) => {
          const module = modules.find((module) => module?.id == service);
          if (module) {
            return (
              <Grid item md={4} key={module?.id}>
                <IncludedServiceCard
                  icon={module?.icon}
                  description={module?.description}
                  service={module?.service}
                />
              </Grid>
            );
          }
        })}
        {contractId && contractStatus != "CANCELLED" && (
          <Grid item md={12} xs={12}>
            <ConsultantContactBox isPeople={true} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export { PeoplePlan };
