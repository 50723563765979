import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Skeleton } from "@mui/material";

import { ConsultantContactBox } from "../../ConsultantContactBox";
import { ContractBox } from "../../ContractBox";
import { ModulesBox } from "../../ModulesBox";
import { ModulesModal } from "../../ModulesModal";
import { PlanBox } from "@components/PlanBox";
import { StyledTitle, StyledText } from "./styled";

import { trpc } from "../../../api/client";

import { segment } from "../../../utils/segment";

import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

const PeoplePlanPage = ({
  peopleContractModules,
}: {
  peopleContractModules: {
    contractId: string;
    modules: string[];
  };
}) => {
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();

  const [openModulesModal, setOpenModulesModal] = useState(false);
  const [modalType, setModalType] = useState("engagement");
  const [value, setValue] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [contractId, setContractId] = useState("");
  const [contractStatus, setContractStatus] = useState("");

  const { data, isLoading } = trpc.getContractsByCompany.useQuery({
    companyId: selectedCompany.id,
  });

  const planValues = {
    totalValue: value,
    baseValue: 19.9,
    upgradeValue: 4.0,
    startDate: initialDate,
    lastChangeDate: "02/11",
    active: contractStatus,
  };

  const statusHierarchy = ["ACTIVE", "WAITING_SIGNATURE", "CANCELLED"];

  const includedModules = [
    {
      icon: "IconChartPie",
      module: "analytics",
      title: "People Analytics",
      segment:
        "company_managecompany_finance_plansandcontracts_peopleanalytics_knowmore_clicked",
    },
    {
      icon: "IconUserPlus",
      module: "flows",
      title: "Admissão",
      segment:
        "company_managecompany_finance_plansandcontracts_admission_knowmore_clicked",
    },
    {
      icon: "IconSchool",
      module: "lms",
      title: "Treinamentos",
      segment:
        "company_managecompany_finance_plansandcontracts_trainnings_knowmore_clicked",
    },
    {
      icon: "IconSpeakerphone",
      module: "engagement",
      title: "Engajamento",
      segment:
        "company_managecompany_finance_plansandcontracts_engagement_knowmore_clicked",
    },
    {
      icon: "IconHierarchy2",
      module: "orgchart",
      title: "Organograma",
      segment:
        "company_managecompany_finance_plansandcontracts_orgchart_knowmore_clicked",
    },
    {
      icon: "IconCheckbox",
      module: "management",
      title: "Gestão personalizada",
      segment:
        "company_managecompany_finance_plansandcontracts_personalizedmanagement_knowmore_clicked",
    },
  ];

  const modulesArray = peopleContractModules?.modules?.map((module) => {
    const objt = includedModules?.find((obj) => obj?.module === module);

    return objt;
  });

  const filteredModulesArray = modulesArray?.filter((obj) => obj);

  if (
    peopleContractModules?.modules?.length == 6 &&
    filteredModulesArray?.length == 5
  ) {
    filteredModulesArray.push({
      icon: "IconCheckbox",
      module: "management",
      title: "Gestão personalizada",
      segment:
        "company_managecompany_finance_plansandcontracts_personalizedmanagement_knowmore_clicked",
    });
  }

  const addOnsIncluded = [
    {
      icon: "IconUpload",
      module: "upload",
      title: "Upload de conteúdo",
      segment:
        "company_managecompany_finance_plansandcontracts_contentupload_knowmore_clicked",
    },
    {
      icon: "IconSignature",
      module: "signature",
      title: "Assinatura eletrônica",
      segment:
        "company_managecompany_finance_plansandcontracts_eletronicsignature_knowmore_clicked",
    },
  ];

  const addOnsNotIncluded = [
    // { icon: "IconUpload", module: "upload", title: "Upload de conteúdo" },
    // {
    //   icon: "IconSignature",
    //   module: "signature",
    //   title: "Assinatura eletrônica",
    // segment:""
    // },
  ];

  useEffect(() => {
    (async () => {
      if (data) {
        const peopleContracts = data?.filter(
          (contract) =>
            contract?.planIds?.includes("people") ||
            contract?.planIds?.includes("sales_flash_rh_ops") ||
            contract?.planIds?.includes("people_sales_default") ||
            contract?.planIds?.includes("sales_flash_rh_full") ||
            contract?.planIds?.includes("people_fc_sales_default") ||
            contract?.planIds?.includes("stand_alone_people_manage_flows") ||
            contract?.planIds?.includes(
              "stand_alone_people_time_and_attendance"
            ) ||
            contract?.planIds?.includes("stand_alone_people_learning_module") ||
            contract?.planIds?.includes(
              "stand_alone_people_performance_module"
            ) ||
            contract?.planIds?.includes(
              "stand_alone_people_engagement_module"
            ) ||
            contract?.planIds?.includes(
              "stand_alone_people_people_analytics_module"
            ) ||
            contract?.planIds?.includes(
              "stand_alone_people_analytics_module"
            ) ||
            contract?.planIds?.includes("stand_alone_people_orgchart") ||
            contract?.planIds?.includes("stand_alone_people_folha_certa")
        );

        const orderedPeopleContract = peopleContracts?.sort((a, b) => {
          return (
            statusHierarchy.indexOf(a?.status) -
            statusHierarchy.indexOf(b?.status)
          );
        });

        const peopleContract = orderedPeopleContract?.[0];

        if (peopleContract) {
          setContractId(peopleContract?.id);
          setContractStatus(peopleContract?.status);

          if (peopleContract?.signDate != null) {
            setInitialDate(peopleContract?.signDate);
          }

          const price: any = peopleContract?.commercialConditions?.find(
            (condition) => condition?.id == "people_price_per_user"
          );

          if (price) {
            setValue(
              `R$ ${(price?.value / 100)?.toFixed(2)?.replace(".", ",")}`
            );
          } else {
            setValue("");
          }
        }
      }
    })();
  }, [data]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={9} xs={12}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height="185px" />
          ) : (
            <PlanBox
              values={planValues}
              onClick={() => {
                if (contractId && contractStatus == "WAITING_SIGNATURE") {
                  navigate("/people/acquisition/checkout");
                }
              }}
            />
          )}
        </Grid>

        <Grid item md={3} xs={12}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height="185px" />
          ) : (
            (contractStatus == "ACTIVE" || contractStatus == "CANCELLED") && (
              <ContractBox
                contractId={contractId}
                url={"/contract-manager/contract"}
                segment={() =>
                  segment({
                    track:
                      "company_managecompany_finance_plansandcontracts_checkcontract_clicked",
                  })
                }
              />
            )
          )}
        </Grid>

        <Grid item md={12}>
          <StyledTitle variant="headline7">
            Confira tudo que está incluso no seu plano
          </StyledTitle>
          <StyledText variant="body3">
            Aproveite cada ferramenta disponível para melhorar a gestão do seu
            time.
          </StyledText>
        </Grid>
        {filteredModulesArray?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Grid item md={4} xs={6}>
                <ModulesBox
                  icon={item?.icon}
                  title={item?.title}
                  module={item?.module}
                  setOpenModulesModal={setOpenModulesModal}
                  setModalType={setModalType}
                  segment={() =>
                    segment({
                      track: item?.segment || "",
                    })
                  }
                />
              </Grid>
            </React.Fragment>
          );
        })}
        {filteredModulesArray?.length == 6 &&
          addOnsIncluded?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item md={4} xs={6}>
                  <ModulesBox
                    icon={item.icon}
                    title={item.title}
                    module={item.module}
                    isUpgrade={true}
                    setOpenModulesModal={setOpenModulesModal}
                    setModalType={setModalType}
                    segment={() =>
                      segment({
                        track: item.segment,
                      })
                    }
                  />
                </Grid>
              </React.Fragment>
            );
          })}
        {addOnsNotIncluded.length ? (
          <>
            <Grid item md={12}>
              <StyledTitle variant="headline7">Turbine seu plano </StyledTitle>
              <StyledText variant="body3">
                Adicione mais facilidade ao seu uso de Flash People.
              </StyledText>
            </Grid>
            {addOnsNotIncluded?.map(
              ({ item, index }: { item: any; index: number }) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item md={4}>
                      <ModulesBox
                        icon={item?.icon}
                        title={item?.title}
                        module={item?.module}
                        isUpgrade={true}
                        setOpenModulesModal={setOpenModulesModal}
                        setModalType={setModalType}
                        segment={() =>
                          segment({
                            track: item.segment,
                          })
                        }
                      />
                    </Grid>
                  </React.Fragment>
                );
              }
            )}
          </>
        ) : (
          <></>
        )}

        <Grid item md={12} xs={12}>
          <ConsultantContactBox
            cancel={contractStatus != "CANCELLED" ? true : false}
          />
        </Grid>
      </Grid>

      <ModulesModal
        onOpen={openModulesModal}
        type={modalType}
        setOpenModulesModal={setOpenModulesModal}
      />
    </>
  );
};

export { PeoplePlanPage };
